/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  defer,
  Navigate,
  useOutletContext,
} from "react-router-dom";
import { map } from "lodash";

import route from "./route";
import My404Component from "pages/404";
import My403Component from "pages/403";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import { AuthLayout, BasicLayout, Spinner } from "components";
import { useAuthContext } from "providers/authProvider";
import { useSelector } from "react-redux";

import { USER } from "services";

const ProtectedRoute = ({ children, routeKey, type, path }) => {
  const { userAccessRights } = useOutletContext();
  const { isAuthenticated, signinRedirect } = useAuthContext();

  const [isChecking, setChecking] = useState(true);
  const [isAuth, setAuthenticated] = useState(false);

  const checkAuthentication = async () => {
    try {
      const resIsAuthenticated = await isAuthenticated();
      setAuthenticated(resIsAuthenticated);
      setChecking(false);
    } catch (error) {
      console.log('error: ', error);
      setChecking(false);
    }
  }

  useMemo(() => {
    checkAuthentication();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isChecking) {
    return (
      <div className="relative min-h-screen">
        <Spinner />
      </div>
    );
  } else {
    if (!isAuth) {
      signinRedirect();
      return (
        <div className="relative min-h-screen">
          <Spinner />
        </div>
      );
    } else {
      if (path === "/") {
        if (!userAccessRights?.[routeKey]?.[type]) {
          const first = Object.keys(userAccessRights).find(
            (key) => userAccessRights[key].canView === true
          );
          if (first) {
            const firstRoute = route
              .filter((x) => x.key === userAccessRights[first].key)
              .map((x) => {
                const firstPath = x.path.split("/").filter((x) => x);
                if (firstPath.length === 1) return firstPath[0];
                return null;
              })
              .filter((x) => x);
            if (firstRoute?.[0])
              return <Navigate to={firstRoute?.[0]} replace />;
          }
        }
      }
      if (!userAccessRights?.[routeKey]?.[type]) {
        return <Navigate to="/unauthorized" replace />;
      } else {
        return (
          <BasicLayout>
            {React.cloneElement(children, {
              accessRight: userAccessRights?.[routeKey],
            })}
          </BasicLayout>
        );
      }
    }
  }
};

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth);

  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: <CALLBACK />,
        path: "/signin-oidc",
      },
      {
        element: <LOGOUT />,
        path: "/logout",
      },
      {
        element: <LOGOUT_CALLBACK />,
        path: "/signout-oidc",
      },
      {
        element: <SILENT_RENEW />,
        path: "/silentrenew",
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.UserId) {
              const { data: userDetail } = await USER.getUserDetail(
                user?.UserId,
                signal
              );
              if (
                userDetail?.userAccess &&
                Array.isArray(userDetail?.userAccess)
              ) {
                const index = userDetail?.userAccess?.findIndex(
                  (x) => x.moduleName?.toLowerCase() === "hukum peraturan"
                );
                if (index > -1) {
                  const userAccess =
                    userDetail?.userAccess?.[index]?.menuModules;
                  const userAccessRights = userAccess.reduce((obj, item) => {
                    return Object.assign(obj, {
                      [item.key]: { ...item },
                    });
                  }, {});
                  return defer({
                    userAccessRights,
                  });
                }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
            return defer({
              userAccessRights: null,
            });
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute routeKey={val.key} type={val.type} path={val.path}>
                <Component />
              </ProtectedRoute>
            ),
          };
        }),
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My403Component />, path: "/unautorized" },
    ]);
  }, [user]);
  return <RouterProvider router={RouteElements} />;
};

export default Routes;
